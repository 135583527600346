/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css"

import { Provider } from "react-redux"
import React from "react"
import ToggleAnalytics from "./src/utils/analytics"
import store from "./src/store/index"

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

// export const onInitialClientRender = () => {
//   const loader = document.getElementById("___loader")
//   const gatsby = document.getElementById("___gatsby")
//   const body = document.getElementById("body")

//   setTimeout(() => {
//     if (loader) {
//       gatsby.style.display = "block"
//       loader.style.opacity = 0
//     }

//     setTimeout(() => {
//       body.style.overflow = "auto"
//       gatsby.style.opacity = 1
//       loader.style.display = "none"
//     }, 450)
//   }, 1000)
// }

export const onRouteUpdate = () => ToggleAnalytics()

// TODO: Fix this, its not currently working
export const shouldUpdateScroll = () => true
