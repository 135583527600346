exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-jsx": () => import("./../../../src/pages/editor.jsx" /* webpackChunkName: "component---src-pages-editor-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-projects-project-js": () => import("./../../../src/templates/projects/project.js" /* webpackChunkName: "component---src-templates-projects-project-js" */),
  "component---src-templates-projects-projects-js": () => import("./../../../src/templates/projects/projects.js" /* webpackChunkName: "component---src-templates-projects-projects-js" */)
}

